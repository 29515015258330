import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import ImageModal from 'components/ui/image-modal';
import { authorKamil as author } from 'data/authors';

import img from 'img/blog/updates/substring_filters.png';

const Content = () => {
  return (
    <div>
      <p>
        New filter operator is now available. For <strong>Name</strong>, <strong>Email</strong>,{' '}
        <strong>URLs</strong> and <strong>Custom params</strong> you can filter sessions using a{' '}
        <strong>substring</strong> value.
      </p>
      <ImageModal
        src={img}
        alt="LiveSession parameters"
        title="Filter sessions using substring value"
      />
      <p className="mt-3" />
    </div>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Substring filtering',
  url: '/blog/substring-filter/',
  description: 'New filters matching option',
  author,
  img,
  date: '2019-05-21',
  category: 'Product Updates',
  group: 'updates',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    group={frontmatter.group}
  />
);
